<template>
  <v-app>
    <v-container fill-height fluid>
      <v-row align="center"
             justify="center">
        <v-col>
          <v-card style="margin: auto" max-width="500">
            <v-card-title>
              {{ $t('evalmee.updateBrowser.title') }}
            </v-card-title>
            <v-card-text>
              <div v-html="$t('evalmee.updateBrowser.content')"></div>
            </v-card-text>
            <v-card-actions>
              <student-help-menu :outlined="false"/>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import StudentHelpMenu from "../../components/quiz/evalmee/student_help_menu.vue"
export default {
  name: "browserTooOld",
  components: { StudentHelpMenu },
}
</script>

<style scoped>

</style>
