<template>
  <div>
    <v-menu
      offset-y
      left
    >
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          :outlined="outlined"
          :text="!outlined"
          small
        >
          <v-icon left>
            mdi-help-circle-outline
          </v-icon>
          {{ $t('live.help.title') }}
        </v-btn>
      </template>
      <v-list>
        <template v-if="quiz.live_chat">
          <v-subheader>
            {{ $t('live.help.subtitleMonitoring') }}
          </v-subheader>
          <v-list-item
            two-line
            @click="showChat"
          >
            <v-list-item-icon>
              <v-icon>chat_bubble</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('live.help.chat') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t('live.help.chatDescription') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </template>

        <v-subheader>
          {{ $t('live.help.subtitleTechnical') }}
        </v-subheader>
        <v-list-item
          two-line
          @click="showCrispChat"
        >
          <v-list-item-icon>
            <v-icon>chat_bubble_outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('live.help.supportChat') }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('live.help.supportChatDescription') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          href="tel:+330187665399"
        >
          <v-list-item-icon>
            <v-icon>call</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('live.help.phone') }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('live.help.phoneDescription') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import chatSupportHelpers from "../../../helpers/chat_support_helpers"

export default {
  name: "StudentHelpMenu",
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    quiz: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  methods: {
    showCrispChat() {
      chatSupportHelpers.showCrispChat()
      chatSupportHelpers.showCrispMessage(this.$t("live.help.supportChatDefaultMessage"))
    },
    showChat() {
      this.$root.$emit("display-chat")
    },
  },
}
</script>

<style scoped>

</style>
